<template lang="pug">
Overlay(unique-key='cognitoLoginPopup' modal-width='SMALL' @closed='reset')
  template(#header)
    div(class='w-full flex justify-center items-center')
      div(class='title' data-testid='modalTitle') Account

  div
    template(v-if='!challenge.isActive')
      BaseForm(@submit='signIn')
        p(class='text-center mb-4') A {{ $sitewideConfig.config?.siteName }} account allows you to manage your orders and save products for later.

        div(class='mb-8')
          BaseFormInput(
            v-model='login.email'
            name='email'
            label='Email address'
            type='email'
            placeholder='Enter Email'
            autocomplete='email'
            validation='required|email'
            inputmode='text'
            data-testid='modalInput'
          )

          div(v-if='login.errorText' class='text-danger text-xs' data-testid='modalError')
            | {{ login.errorText }}

        BaseButton(class='w-full' :is-waiting='login.isLoading' data-testid='modalSignIn' type='submit') Sign In

    template(v-else)
      BaseForm(@submit='completeLogin')
        p(class='text-center mb-4') A code has been sent to your email. Please enter the code to access your account.

        div(class='mb-8')
          BaseFormInput(
            v-model='challenge.code'
            name='code'
            label='Code'
            type='text'
            placeholder='Enter code'
            validation='required|number'
            data-testd='modalCodeInput'
            inputmode='numeric'
          )

          div(v-if='challenge.errorText' class='text-danger text-xs' data-testid='modalCodeError')
            | {{ challenge.errorText }}

        BaseButton(data-testid='modalSubmit' class='w-full' :is-waiting='challenge.isLoading' type='submit') Submit
</template>

<script lang="ts" setup>
const { $cognito, $uiEvents } = useNuxtApp()

const login = ref({
  email: '',
  errorText: '',
  isLoading: false,
})

const challenge = ref({
  isActive: false,
  code: '',
  errorText: '',
  isLoading: false,
})

watch(
  () => login.value.email,
  () => {
    login.value.errorText = ''
  }
)

watch(
  () => challenge.value.code,
  () => {
    challenge.value.errorText = ''
  }
)

function reset() {
  login.value = {
    email: '',
    errorText: '',
    isLoading: false,
  }
  challenge.value = {
    isActive: false,
    code: '',
    errorText: '',
    isLoading: false,
  }
}

function close() {
  // Make sure that if an api call is running, don't let the modal close
  if (login.value.isLoading || challenge.value.isLoading) return

  $cognito.closeModal()
}

async function signIn() {
  // Double click protection
  if (login.value.isLoading) return

  // Attempt to login
  try {
    login.value.isLoading = true

    // Make sure email doesn't have whitespace
    const email = login.value.email.trim()
    const userId = await $cognito.login(email)

    // Event is used by Amplitude
    if (userId) $uiEvents.$emit('cognitoSignUp', userId)

    challenge.value.isActive = true
  } catch (error) {
    console.error(error)
    login.value.errorText = error.message
  } finally {
    login.value.isLoading = false
  }
}

async function completeLogin() {
  if (challenge.value.isLoading) return

  // Check if email is valid
  if (!challenge.value.code) {
    challenge.value.errorText = 'Code is required'
    return
  }

  // Attempt to send challenge code
  try {
    challenge.value.isLoading = true
    // Make sure code doesn't have whitespace
    const code = challenge.value.code.trim()

    await $cognito.completeLogin(code)
    $uiEvents.$emit('cognitoSignIn')

    close()
  } catch (error) {
    console.log('Error', error)
    challenge.value.errorText = 'Invalid code'
  } finally {
    challenge.value.isLoading = false
  }
}
</script>
