export default defineNuxtPlugin(() => {
  const { $sitewideConfig } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  const { $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration stripe')
  if (!isIntegrationEnabled('stripe')) return

  const apiKey = $sitewideConfig.config.stripeKey
  const script = runScriptOnDemand()

  let stripe: any

  script.addSrc(`https://js.stripe.com/v3/`)
  script.addCallback(() => {
    stripe = window.Stripe(apiKey)
  })

  function load() {
    return new Promise((resolve) => {
      script.addCallback(() => {
        resolve(stripe)
      })

      script.addErrorCallback(() => {
        resolve(null)
      })

      script.load()
    })
  }

  return {
    provide: {
      stripe: {
        load,
      },
    },
  }
})
