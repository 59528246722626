export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const fitmentDisplayStore = useFitmentDisplayStore()

  // === PRODUCTS === //

  // Data coming in from the Cart Store
  const formatCartProduct = (product: any, position = 1) => {
    const isQuickshipable = isQuickship(product?.shipping)
    const { eddMessage, eddMin, eddMax } = estimatedDelivery(product?.shipping)
    const isEligibleFreeShipping = product?.shipping?.isEligibleFreeShipping !== undefined

    if (!product) return {}
    const obj = {
      name: product.productName,
      sku: product.sku,
      position,
      quantity: product.qty,
      category: product.category,
      // brand - waiting on back end
      year: product.fitmentData?.year,
      make: product.fitmentData?.makeName,
      model: product.fitmentData?.modelName,
      price: product.salePrice / 100,
      product_id: product.productSlug, // cart also has skuSlug, but receipt obj does not :(
      ...(isEligibleFreeShipping && {
        isEligibleFreeShipping: product?.shipping?.isEligibleFreeShipping,
      }),
      ...(isQuickshipable !== null && { quickship: isQuickshipable }),
      ...(eddMessage && { edd_message: eddMessage }),
      ...(eddMin && { edd_min: eddMin }),
      ...(eddMax && { edd_max: eddMax }),
      ...(product.installation?.subtotal && {
        installation_price: product.installation.subtotal,
      }),
    }

    if (product.productLineSlug)
      obj.url = `https://${$sitewideConfig.domain}/p/${product.productLineSlug}/${product.productSlug}/`

    // If we have a warranty then add it to the obj
    if (product.warranty?.id) {
      const warrantyArray = Array.isArray(product.warranty) ? product.warranty : [product.warranty]

      obj.warranty = warrantyArray.map((warranty: any) => ({
        sku: warranty.id,
        price: warranty.price / 100,
      }))
    }

    return obj
  }

  // Data coming in from the Product Store
  const formatProductData = (product: any) => {
    const isQuickshipable = isQuickship(product.shipping)
    const { eddMessage, eddMin, eddMax } = estimatedDelivery(product.shipping)
    const isEligibleFreeShipping = product?.shipping?.isEligibleFreeShipping !== undefined
    const primaryFitment = getFitment()
    const category = getCategoryTreeFromCrumbs(product.crumbs)

    return {
      name: product.name,
      ...(product.sku && { sku: product.sku }),
      ...(product.skuSlug && { product_id: product.skuSlug }),
      quantity: 1,
      ...(category && { category: category.slugs.join('/') }),
      brand: product.brand.name,
      ...(product.actualPrice && { price: product.actualPrice.min / 100 }),
      currency: 'usd',
      ...(product.actualPrice && { value: product.actualPrice.min / 100 }), // Total value of the product after quantity
      ...(isEligibleFreeShipping && {
        isEligibleFreeShipping: product?.shipping?.isEligibleFreeShipping,
      }),
      isOutOfStock: product.isOutOfStock,
      ...(isQuickshipable && { quickship: isQuickshipable }),
      ...(isQuickshipable !== null && { quickship: isQuickshipable }),
      ...(eddMessage && { edd_message: eddMessage }),
      ...(eddMin && { edd_min: eddMin }),
      ...(eddMax && { edd_max: eddMax }),
      ...(primaryFitment && primaryFitment),
    }
  }

  // Used by formatCartProduct() and products
  const isQuickship = (shippingData: any) => {
    if (!$sitewideConfig.config.sameDayShippingEnabled || !shippingData) return null
    return !!shippingData.sameDay || shippingData.sameDayShipping
  }

  const estimatedDelivery = (shippingData: any) => {
    // Estimated Delivery Details
    let eddMessage = null
    let eddMin = null
    let eddMax = null
    // Early out if shippingData undefined
    if (!shippingData) return { eddMessage, eddMin, eddMax }

    if (shippingData.estimatedDeliveryDate) {
      eddMessage = getEddText(shippingData, true)
      eddMin = shippingData.estimatedDeliveryDate.min
      eddMax = shippingData.estimatedDeliveryDate.max
    }
    return { eddMessage, eddMin, eddMax }
  }

  const getFitment = () => {
    const year = fitmentDisplayStore.getSelect('year')
    const make = fitmentDisplayStore.getSelect('make')
    const model = fitmentDisplayStore.getSelect('model')

    return {
      ...(year?.currentValue && { year: year.currentValue }),
      ...(make?.currentValue && { make: make.currentValue }),
      ...(model?.currentValue && { model: model.currentValue }),
    }
  }

  const getFullFitment = () => {
    const year = fitmentDisplayStore.getSelect('year')
    const make = fitmentDisplayStore.getSelect('make')
    const model = fitmentDisplayStore.getSelect('model')
    const bed = fitmentDisplayStore.getSelect('bed')
    const body = fitmentDisplayStore.getSelect('body')
    const engine = fitmentDisplayStore.getSelect('engine')

    return {
      ...(year?.currentValue && { year: year.currentValue }),
      ...(make?.currentValue && { make: make.currentValue }),
      ...(model?.currentValue && { model: model.currentValue }),
      ...(bed?.currentValue && { bed: bed.currentValue }),
      ...(body?.currentValue && { body: body.currentValue }),
      ...(engine?.currentValue && { engine: engine.currentValue }),
    }
  }

  // since we don't have a 'real' category tree obj, we'll have to leverage crumbs :(
  // note: this may contain different category verbiage than real life (UWP = Folding vs classic RT = Folding Tonneau Covers)
  const getCategoryTreeFromCrumbs = (crumbs: any) => {
    if (!crumbs || crumbs.length < 2) return // should at least have home & pline node

    const names = []
    const slugs = []
    // skip home & last node (pline)
    for (let i = 1; i < crumbs.length - 1; i++) {
      names.push(crumbs[i].title)
      slugs.push(crumbs[i].urlData.params[`lvl${i - 1}`])
    }

    return {
      names,
      slugs,
    }
  }

  // Used by emitCart() and checkout
  // loops through an array of cart products and returns them
  const getCartProducts = (products: any) => {
    return products.map((product: any, index: number) => {
      return formatCartProduct(product, index + 1)
    })
  }

  return {
    formatCartProduct,
    formatProductData,
    getFitment,
    getFullFitment,
    getCategoryTreeFromCrumbs,
    getCartProducts,
    isQuickship,
  }
}
