import Bugsnag, { type Client as BugsnagClient } from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default defineNuxtPlugin(() => {
  const { $sitewideConfig, vueApp } = useNuxtApp()
  const utils = useUtils()
  const config = useRuntimeConfig()
  const appConfig = useAppConfig()
  let bugsnagClient: BugsnagClient

  // Only load bugsnag client side
  if (!import.meta.server && $sitewideConfig.config.bugsnagEnabled && $sitewideConfig.config.bugsnagApiKey) {
    bugsnagClient = Bugsnag.start({
      apiKey: $sitewideConfig.config.bugsnagApiKey,
      plugins: [new BugsnagPluginVue()],
      appVersion: appConfig.buildId,
      // This is undefined for development because the client is reading the node env internally
      // and manually setting the value of this to 'development'.
      // I didn't feel the need to overwrite it to the same value so we only set it for when the app is not running in dev mode.
      releaseStage: utils.isDev() ? undefined : config.public.mode,
    })

    const plugin = Bugsnag.getPlugin('vue')
    if (plugin) vueApp.use(plugin)
  }

  function sendMessage(message: string, severity: 'info' | 'warning' | 'error', metaData?: Record<string, any>) {
    if (!bugsnagClient) return

    bugsnagClient.notify(message, (event) => {
      event.severity = severity
      if (metaData) event.addMetadata('Extra Info', metaData)
    })
  }

  function leaveCrumb(message: string) {
    if (!bugsnagClient) return
    bugsnagClient.leaveBreadcrumb(message)
  }

  return {
    provide: {
      bugsnag: { notify: sendMessage, leaveCrumb },
    },
  }
})
