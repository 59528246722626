<template lang="pug">
CmsEditorTabs(
  v-if='isHeaderStory'
  :data='[ { name: "Standard", clickHandler: () => setIsSimple(false) }, { name: "Simple", clickHandler: () => setIsSimple(true) }, ]'
)

header(class='pointer-events-none' :class='{ "sticky top-0 z-10": isHeaderSticky }')
  div(v-if='headerData' id='stickyHeader' ref='header' class='pointer-events-auto' :class='{ "relative z-[1]": isHeaderSticky }')
    CmsStoryblok(v-if='isSimpleMode' :sections='headerData.headerSimple')
    CmsStoryblok(v-else :sections='headerData.headerStandard')

  //- delay-[50ms] was added here to fix an issue on Safari mobile where the navbar would "blink"
  //- before changing the elements position. I think it has to do with Safari hiding/showing their
  //- address tab bar when you scroll. Somehow its interfering with the animation, so delaying it by
  //- 50ms lets Safari do whatever its doing before translating the element.
  div(
    v-if='!isSimpleMode'
    id='stickyNavbar'
    ref='headerNav'
    class='pointer-events-auto'
    :class='{ "delay-[50ms] lg:delay-0 lg:transition-transform ": hideNavbar, "relative z-0": isHeaderSticky }'
  )
    HeaderNavbar

  HeaderNavbarMobile(v-if='$device.isSmall')

  FitmentModal
  CognitoModal(v-if='isIntegrationEnabled("cognito")')
  GeolocationModal(v-if='isIntegrationEnabled("geolocation")')
  OrderStatusLoginModal
  Overlay(unique-key='sc' title='Email Cart' :enable-dark-mode='true' :is-persistent='true')
    LazyCartSaveModal
</template>

<script setup lang="ts">
const { isSimple = false } = defineProps<{ isSimple?: boolean }>()
const { $sitewideConfig, $storyblok, $device } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

const isSimpleToggle = ref(false)
const header = ref<HTMLElement | null>(null)
const headerNav = ref<HTMLElement | null>(null)
const lastScrollY = ref(0)

const { data: previewData } = await useAsyncData('header-data', async () => {
  if (!$storyblok.isPreview.value || $storyblok.contentType.value !== 'header') return null

  const preview = await $storyblok.getPreviewStory<HeaderStory>()
  return preview?.story.content ?? null
})

const headerData = computed(() => {
  return previewData.value || $sitewideConfig.header
})

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return isSimpleToggle.value
  return isSimple
})

const isHeaderStory = computed(() => {
  return $storyblok.contentType.value === 'header'
})

function setIsSimple(bool: boolean) {
  isSimpleToggle.value = bool
}

const isHeaderSticky = computed(() => {
  return headerData?.value?.stickyEnabled && !$storyblok.isEditing.value
})

const hideNavbar = computed(() => {
  return isHeaderSticky.value && $sitewideConfig.navbar.value?.content?.hideOnSticky
})

onMounted(async () => {
  // Add a listener to update the content when in editor mode
  $storyblok.addListener((story) => {
    if ($storyblok.isHeaderStory(story)) previewData.value = story.story.content
  })

  window.addEventListener('scroll', checkStickyNavbar)
})

onUnmounted(() => {
  window.removeEventListener('scroll', checkStickyNavbar)
})

provide('getUniversalLinkTarget', getUniversalLinkTarget)

function getUniversalLinkTarget(event: any) {
  let target = event.target
  // Elements like IMG that are inside an A tag will report the IMG not the A
  // .closest will look for the closest parent matching our selector
  if (event.target?.tagName !== 'A') target = event.target.closest('[data-element-type="universalLink"]')

  // Early out if we don't find an A tag of elementType universalLink
  // Early out if we don't have an href
  if (target?.dataset?.elementType !== 'universalLink') return

  return target
}

function checkStickyNavbar() {
  // Early out if we do not want to hide the nav bar, or header or nav don't exist
  if (!hideNavbar.value || !header.value || !headerNav.value) return

  const headerTop = header.value.getBoundingClientRect().top
  const offset = headerTop + header.value.getBoundingClientRect().height
  // We only want to hide the nav bar when the header is sticking to the top of the page
  if (window.scrollY > offset) {
    // If we are on mobile/tablet always hide the nav bar (fitment button)
    if ($device.value.isSmall) {
      headerNav.value.style.transform = `translateY(-${headerNav.value?.clientHeight}px)`
      return
    }

    // Get current window scroll position
    const scrollTop = window.scrollY

    // Check current scroll positon vs last scroll position
    // Scrolling down
    if (scrollTop >= lastScrollY.value)
      headerNav.value.style.transform = `translateY(-${headerNav.value?.clientHeight}px)`
    // Scrolling up
    else if (scrollTop < lastScrollY.value) headerNav.value.style.transform = `translateY(0)`

    // Update the last scroll position
    lastScrollY.value = window.scrollY
  } else if (window.scrollY <= offset) {
    // Reset navbar if header is no longer sticking to the top of page
    headerNav.value.style.transform = `translateY(0)`
  }
}
</script>
