export default defineNuxtPlugin(() => {
  const isMobileQuery = window.matchMedia(`screen and (max-width: 767px)`)
  const isSmallQuery = window.matchMedia(`screen and (max-width: 1023px)`)
  const isDesktopQuery = window.matchMedia(`screen and (min-width: 1024px)`)
  const device = useState<Device>('device')

  onNuxtReady(() => {
    isMobileCheck(isMobileQuery)
    isSmallCheck(isSmallQuery)
    isDesktopCheck(isDesktopQuery)
  })

  if (isMobileQuery.addEventListener) isMobileQuery.addEventListener('change', isMobileCheck)
  if (isSmallQuery.addEventListener) isSmallQuery.addEventListener('change', isSmallCheck)
  if (isDesktopQuery.addEventListener) isDesktopQuery.addEventListener('change', isDesktopCheck)

  function isMobileCheck(e: Pick<MediaQueryListEvent, 'matches'>) {
    device.value.isMobile = e.matches
    device.value.key = e.matches ? 'mobile' : 'desktop'
  }

  function isSmallCheck(e: Pick<MediaQueryListEvent, 'matches'>) {
    device.value.isSmall = e.matches
  }

  function isDesktopCheck(e: Pick<MediaQueryListEvent, 'matches'>) {
    device.value.isDesktop = e.matches
  }

  return {
    provide: {
      device,
    },
  }
})
