<template lang="pug">
div(v-if='$storyblok.isEditing.value' class='relative z-[61] border-b mb-2 space-x-2 uppercase font-bold')
  template(v-for='tab in data' :key='tab.name')
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": isActive !== tab.name, "bg-white": isActive === tab.name }'
      @click='[tab.clickHandler(), setActive(tab.name)]'
    ) {{ tab.name }}
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const isActive = ref('')

interface tabData {
  name: string
  clickHandler: () => void
}

function setActive(tab: string) {
  isActive.value = tab
}

const { data } = defineProps<{
  data: tabData[]
}>()

onMounted(() => {
  if (data.length > 1) setActive(data[0].name)
})
</script>
