export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  // Note: Segment is disabled by Default in useUtils()
  // So to run locally you will need to modify isDisabledInDev and isDisabledInPreview
  if (!isIntegrationEnabled('segment')) return

  // ===== Load the JS ==== //
  const segment = useSegmentHelper()
  const { $uiEvents, $sitewideConfig, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration segment')

  /* eslint-disable */
  // prettier-ignore
  !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey=$sitewideConfig.config.segmentApiKey;;analytics.SNIPPET_VERSION="5.2.0";
  analytics.load($sitewideConfig.config.segmentApiKey);
  }}();
  /* eslint-enable */

  // ===== A promise to ensure that the JS is ready ==== //
  function _isReady() {
    return new Promise((resolve) => {
      window.analytics.ready(resolve)
    })
  }

  // ===== Exposed method to get the Segment Anonymous ID ==== //
  async function getAnonId() {
    await _isReady()
    return window.analytics.user().anonymousId()
  }

  // =====  Wire up default events
  $uiEvents.$on('firstLoaded', (data) => segment.emitPage(data))
  $uiEvents.$on('firstLoaded', () => segment.emitIdentify())
  $uiEvents.$on('routeChanged', ({ to, from }) => segment.emitPage(to, from))
  $uiEvents.$on('amplitudeExposure', (exposure) => segment.track('$exposure', exposure))
  $uiEvents.$on('cmsLinkClicked', (obj) => segment.track('Clicked', obj))
  $uiEvents.$on('errorLog', ({ errorType }) => segment.track('Error Log', { errorType }))

  // Wire up Listeners
  useSegmentCartManagerListener()
  useSegmentCheckoutListener()
  useSegmentDealerLocatorListener()
  useSegmentExtendListener()
  useSegmentFitmentListener()
  useSegmentInstallernetListener()
  useSegmentMyAccountListener()
  useSegmentNavigationListener()
  useSegmentNewsletterListener()
  useSegmentProductsListener()
  useSegmentSaveCartListener()
  useSegmentSearchListener()
  useSegmentConstructorListener()

  return {
    provide: {
      segment: {
        getAnonId,
      },
    },
  }
})
