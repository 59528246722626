<template lang="pug">
div
  //- Tool bar for the storyblok editor
  CmsEditorTabs(
    v-if='isLinkbarStory'
    :data='[ { name: "Standard", clickHandler: () => setIsSimple(false) }, { name: "Simple", clickHandler: () => setIsSimple(true) }, ]'
  )

  template(v-if='linkBarData')
    CmsStoryblok(v-if='isSimpleMode' :sections='linkBarData?.simpleLinkbar')
    CmsStoryblok(v-else :sections='linkBarData?.standardLinkbar')
</template>

<script setup lang="ts">
const { isSimple = false } = defineProps<{ isSimple?: boolean }>()
const { $sitewideConfig, $storyblok } = useNuxtApp()

const { data: previewData } = await useAsyncData('linkbar-data', async () => {
  if (!$storyblok.isPreview.value || $storyblok.contentType.value !== 'link-bar') return null

  const preview = await $storyblok.getPreviewStory<LinkBarStory>()
  return preview?.story.content ?? null
})

const isSimpleToggle = ref(false)

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return isSimpleToggle.value
  return isSimple
})

const linkBarData = computed(() => {
  return previewData.value || $sitewideConfig.linkBar
})

const isLinkbarStory = computed(() => {
  return $storyblok.contentType.value === 'link-bar'
})

const setIsSimple = (bool: boolean) => {
  isSimpleToggle.value = bool
}
</script>
