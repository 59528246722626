
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "172c2ee8c7b30dba73f9c9d4c493eb30675c840e",
  "nuxt": {
    "buildId": "02b9f6bd-850c-46a9-94f4-838306249787"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
