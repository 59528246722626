<template lang="pug">
div(
  v-show='searchQuery.length > 0'
  id='searchResults'
  data-cnstrc-autosuggest
  data-testid='searchResults'
  class='absolute bg-white z-60 top-full left-0 right-0 text-black -mt-[1px] lg:min-w-[400px] lg:max-h-[650px] lg:overflow-y-auto'
)
  AlgoliaWrapper(v-if='showCategoriesInSearch' :search-context='searchBar' index='categories')
    AlgoliaResults
      template(#results)
        div(class='p-3 border-t-[1px] border-solid border-gray-light')
          p(class='text-lg font-bold mt-0 mr-0 mb-[5px] ml-0') Categories
          AlgoliaResults
            template(#result='{ result }')
              UniversalLink(
                :key='result.objectID'
                class='block text-black'
                :link='formatCategoryUrl(result.urlData)'
                @click='$emit("close")'
              )
                InlineHtml(class='text-xs' :text='result.name')

  AlgoliaWrapper(v-if='showBrandsInSearch' :search-context='searchBar' index='brands')
    AlgoliaResults
      template(#results)
        div(class='p-3 border-t-[1px] border-solid border-gray-light pl-0 pr-0')
          p(class='text-lg font-bold mt-0 mr-0 mb-[5px] ml-0 px-3') Brands
          AlgoliaResults(class='grid grid-cols-5')
            template(#result='{ result }')
              div(:key='result.objectID' class='text-center px-3')
                UniversalLink(class='text-black' :link='`/b/${result.slug}`')
                  div(@click='$emit("close")')
                    img(
                      class='my-0 mx-auto'
                      :src='getImageUrl(result.image.key, result.image.filename, 56, 56, 70, "fff")'
                      width='56'
                      height='56'
                    )
                    InlineHtml(class='block text-xs leading-[1.25]' :text='result.name')

  AlgoliaWrapper(:search-context='searchBar' index='products')
    AlgoliaResults
      template(#results)
        div(class='p-3 border-t-[1px] border-solid border-gray-light')
          p(class='text-lg font-bold mt-0 mr-0 mb-[5px] ml-0') Products
          AlgoliaResults
            template(#result='{ result }')
              UniversalLink(
                :key='result.objectID'
                data-cnstrc-item-section='Products'
                :data-cnstrc-item-name='result.productLineName'
                :data-cnstrc-item-id='result.productLineSlug'
                class='block py-4 px-0 text-black border-t-[1px] first:border-t-0 border-solid border-gray-lighter hover:bg-gray-lighter hover:no-underline focus:bg-gray-lighter focus:no-underline'
                :link='`/p/${result.productLineSlug}`'
                @click='$emit("close")'
              )
                div(class='flex')
                  div(class='flex-none')
                    div(v-if='result.image' class='image')
                      img(
                        class='max-w-[64px] mr-4'
                        :alt='result.productName'
                        :src='getImageUrl(result.image.key, result.image.filename, 64, 48)'
                        width='64'
                        height='48'
                      )

                  div(:class='!$sitewideConfig.config.nonTransactionalEnabled ? "flex-1" : "flex items-center"')
                    div
                      InlineHtml(class='block leading-[1.2] mb-1' :text='result.productLineName')
                      p(v-if='!$sitewideConfig.config.nonTransactionalEnabled')
                        span(class='uppercase text-xs') From:&nbsp;
                        strong {{ formatCents(result.salePrice) }}

  div(v-if='hasNoResults' class='p-3 border-t-[1px] border-solid border-gray-light text-sm text-gray-dark')
    | No results found
</template>

<script lang="ts" setup>
defineEmits(['close'])

const { $algolia, $sitewideConfig, $uiEvents } = useNuxtApp()
const { getImageUrl } = useUrls()
const fitmentFilters = useFitmentFilters()
const realtruckFilters = useRealtruckFilters()

// Set SearchContext here so we don't have to do $algolia.searchContexts everywhere
const searchBar = $algolia.searchContexts.searchBar

const { showCategoriesInSearch, showBrandsInSearch, searchQuery } = defineProps<{
  showCategoriesInSearch?: boolean
  showBrandsInSearch?: boolean
  searchQuery: string
}>()

// Computed properties
const hasNoResults = computed(() => {
  return searchBar.indexes.every(
    (index) => index.state.value.resultCount !== null && index.state.value.resultCount === 0
  )
})

const productIndex = computed(() => searchBar.indexes.find((index) => index.key === 'products'))
const brandIndex = computed(() => searchBar.indexes.find((index) => index.key === 'brands'))

function formatCategoryUrl(urlData: any) {
  const { params } = urlData
  let url = '/c'
  for (const key in params) {
    url += `/${params[key]}`
  }
  return url
}

watch(
  () => searchQuery,
  (newVal) => {
    productIndex.value.clearFilters()
    productIndex.value.addFilters('isAccessory', false)

    // NonTransactional Needs to only show RT Products
    if ($sitewideConfig.config.nonTransactionalEnabled) {
      realtruckFilters.addFilter(productIndex.value)
      realtruckFilters.addFilter(brandIndex.value, 'name')
    }

    fitmentFilters.update(productIndex.value)
    searchBar.setQuery(newVal).search()
  }
)

watch(hasNoResults, (newVal) => {
  const keyword = searchQuery
  if (newVal) $uiEvents.$emit('noSearchResults', keyword)
})
</script>
