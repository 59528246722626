<template lang="pug">
//- A wrapper div is required to use the Observer for amplitude tracking
div(v-if='isEnabled')
  template(v-if='forceShow || shouldRender')
    slot
</template>

<script lang="ts" setup>
const { $sitewideConfig, $amplitude } = useNuxtApp()
const { observe } = useRunOnVisible()
const { isIntegrationEnabled } = useUtils()
const fitmentDisplayStore = useFitmentDisplayStore()

// Define Props
const { productLineSlug, forceShow, shouldTrack } = defineProps<{
  productLineSlug?: string | null
  forceShow?: boolean
  shouldTrack?: boolean
}>()

const isInTestGroup = ref(false)
const validFitment = ref([
  {
    startYear: 2021,
    endYear: 2023,
    make: 'ford',
    model: 'f150',
    beds: ['5-7-bed', '6-7-bed'],
    bodies: ['super-crew'],
  },
  {
    startYear: 2021,
    endYear: 2023,
    make: 'ford',
    model: 'f150',
    beds: ['6-7-bed', '8-bed'],
    bodies: ['super-cab'],
  },
  {
    startYear: 2021,
    endYear: 2023,
    make: 'ford',
    model: 'f150',
    beds: ['6-6-bed', '8-bed'],
    bodies: ['regular-cab'],
  },
])

const isEnabled = computed(() => {
  return $sitewideConfig.config.protexEnabled && isIntegrationEnabled('amplitude') && isIntegrationEnabled('segment')
})

const shouldRender = computed(() => {
  return isEnabled.value && productLineSlug
    ? isInTestGroup.value && checkFitment.value && checkProduct.value
    : isInTestGroup.value && checkFitment.value
})

const checkProduct = computed(() => {
  const validSlugs = [
    'a-r-e-q-series-truck-cap', // Future Product Launch
    'bakflip-mx4-tonneau-cover',
    'bak-revolver-x4s-tonneau-cover',
    'extang-trifecta-2-0-tonneau-cover',
    'retraxpro-mx-tonneau-cover',
    'retrax-eq-tonneau-cover',
    'undercover-elite-lx-tonneau-cover',
    'bedrug-full-bedliner',
    'bedrug-xlt-bed-mat',
    'husky-liners-heavy-duty-truck-bed-mat',
    'grid-x-rack', // Future Product Launch
    'realtruck-gorack',
    'undercover-swing-case-truck-bed-toolbox',
    'bushwacker-pocket-style-fender-flares',
    'husky-liners-x-act-contour-floor-liners',
    'husky-liners-weatherbeater-floor-liners',
    'go-rhino-br6-front-winch-bumper',
    'husky-liners-custom-molded-mud-flaps',
    'go-rhino-br20-5-rear-bumper',
    'go-rhino-ceros-low-profile-roof-rack',
    'go-rhino-rb20-running-boards',
    'amp-research-power-step',
    'avs-lo-profile-vent-visors',
    'rugged-rear-wheel-well-liners',
  ]
  return validSlugs.includes(productLineSlug || '')
})

const checkFitment = computed(() => {
  if (!fitmentDisplayStore.hasFullFitment) return false
  let fitmentOptions = [...validFitment.value]

  // We know they have selected all required fields
  // So we are matching those required fields against our list of valid fitment options
  // and filtering out any fitement options that don't match
  return fitmentDisplayStore.requiredSelectTypes.every(({ type, currentValue }) => {
    switch (type) {
      case 'year':
        fitmentOptions = fitmentOptions.filter(({ startYear, endYear }) => {
          return startYear <= currentValue && endYear >= currentValue
        })
        break
      case 'make':
        fitmentOptions = fitmentOptions.filter(({ make }) => {
          return make === currentValue
        })
        break
      case 'model':
        fitmentOptions = fitmentOptions.filter(({ model }) => {
          return model === currentValue
        })
        break
      case 'bed':
        fitmentOptions = fitmentOptions.filter(({ beds }) => {
          return beds.includes(currentValue)
        })
        break
      case 'body':
        fitmentOptions = fitmentOptions.filter(({ bodies }) => {
          return bodies.includes(currentValue)
        })
        break
    }

    // If we still have 1 or more valid fitment options
    // then we are good to show the Protex link
    return fitmentOptions.length > 0
  })
})

watch(shouldRender, (newVal) => {
  if (!newVal || !shouldTrack) return
  observe(document.body, () => $amplitude.track('protex-poc-test'))
})

onMounted(async () => {
  if (!isEnabled.value) return

  const variant = await $amplitude.getVariant('protex-poc-test')
  isInTestGroup.value = variant?.value === 'treatment'

  if (shouldTrack && variant?.value === 'control') $amplitude.track('protex-poc-test')
})
</script>
