<template lang="pug">
div
  div(
    class='text-white flex items-center justify-between cursor-pointer lg:p-0'
    data-testid='locationDisplay'
    @click='openLocationPopup'
  )
    div(class='flex items-center')
      LocationIcon(class='w-4 h-4 mr-1 fill-current')
      span {{ $geolocation.formattedLocation }}
    div(class='block text-gray-lighter lg:hidden')
      span Change
</template>

<script lang="ts" setup>
import LocationIcon from '@/assets/location.svg?component'

const { $geolocation } = useNuxtApp()

function openLocationPopup() {
  $geolocation.openModal()
}
</script>
