export default defineNuxtPlugin(() => {
  const { $sitewideConfig } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('googlePlaces')) return

  const { $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration google places')

  const apiKey = $sitewideConfig.config.googlePlacesKey
  const { addSrc, load: loadScript } = runScriptOnDemand()
  let isLoaded = false

  addSrc(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=googlePlacesCallback`)

  // here we'll return a promise so the UI can just await instead of chucking stuff into a callback
  function load() {
    loadScript()

    return new Promise<void>((resolve) => {
      if (isLoaded) return resolve()

      const interval = setInterval(() => {
        if (isLoaded) {
          clearInterval(interval)
          resolve()
        }
      }, 500)
    })
  }

  window.googlePlacesCallback = () => {
    isLoaded = true
  }

  return {
    provide: {
      googlePlaces: {
        load,
      },
    },
  }
})

declare global {
  interface Window {
    googlePlacesCallback: Function
  }
}
